var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"carousel-box"},[_c('div',{staticClass:"carousel"},[_c('el-carousel',{staticClass:"text-box-parent",style:({ 'z-index': _vm.$store.state.index }),attrs:{"height":"150px","autoplay":"","append-to-body":true}},_vm._l((_vm.carouselImgList),function(item){return _c('el-carousel-item',{key:item.id},[(item.id === 1)?_c('div',{staticClass:"text-box"},[_c('div',{staticClass:"one"},[_vm._v("掌动游戏")]),_c('div',{staticClass:"two"},[_vm._v("打造基于游戏的全产业链生态")])]):_vm._e(),_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.windowWidth>992?item.imgSrc:item.mobileImg,"alt":""}})])}),1)],1)]),_vm._m(0),_c('div',{staticClass:"product"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('div',{staticClass:"two"},[_vm._v("OUR  PRODUCT")]),_c('div',{staticClass:"content"},[_vm._l((_vm.lunbo),function(item,index){return _c('div',{key:index,class:{
            'content-top': true,
            active: _vm.BottomCurrentIndex === index,
          }},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":item.imgSrc,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-text-box"},[_c('div',{staticClass:"right-title"},[_vm._v(_vm._s(item.title))]),_vm._m(2,true),_c('div',{staticClass:"right-content"},[_vm._v(_vm._s(item.content))])])])])}),_c('div',{staticClass:"content-bottom"},_vm._l((_vm.lunbo),function(item,index){return _c('div',{key:index,class:{'content-bottom-item': true,
              active: _vm.BottomCurrentIndex === index,
            },on:{"click":function($event){return _vm.bottomBtn(index)}}},[_c('img',{attrs:{"src":item.iconImg,"alt":""}})])}),0)],2)])]),_c('div',{staticClass:"cooperation"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"textOne"},[_vm._v("CONTACT")]),_c('div',{staticClass:"textTwo"},[_vm._v("商务合作")]),_c('div',{staticClass:"btn"},[_c('div',{staticClass:"btn-box",on:{"click":_vm.contactBtn}},[_c('div',[_vm._v("联系我们")]),_vm._m(3)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"icon-title"},[_c('img',{attrs:{"src":require("../assets/titleIcon.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("关于掌动")]),_c('div',{staticClass:"icon-title"},[_c('img',{attrs:{"src":require("../assets/titleIcon.png"),"alt":""}})])]),_c('div',{staticClass:"two"},[_vm._v("ABOUT  PALM")]),_c('div',{staticClass:"three"},[_vm._v("掌动致力于创造开放卓越的游戏生态")]),_c('div',{staticClass:"four"},[_c('div',{staticClass:"four-item"},[_vm._v("掌动科技成立于2013年6月，是一家致力于集自主研发、运营于一体的游戏全产业链的高科技互联网公司。在游戏产")]),_c('div',{staticClass:"four-item"},[_vm._v("业中具有顶尖的技术和丰富的资源，对游戏的各方面有深刻的认识和优势壁垒。公司秉承着“掌动科技，快乐生活”的理")]),_c('div',{staticClass:"four-item"},[_vm._v("念，致力于游戏生态全产业链打造，为中国游戏创造开放卓越的游戏生态。")])]),_c('div',{staticClass:"mobile-four"},[_c('div',{staticClass:"four-item"},[_vm._v(" 掌动科技成立于2013年6月，是一家致力于集自主研发、运营于一体的游戏全产业链的高科技互联网公司。 在游戏产业中具有顶尖的技术和丰富的资源，对游戏各方面有深刻的认识和优势壁垒。 ")])]),_c('div',{staticClass:"five"},[_c('div',{staticClass:"five-item"},[_c('div',{staticClass:"five-item-title"},[_vm._v("1.2亿")]),_c('div',[_vm._v("掌动注册用户")])]),_c('div',{staticClass:"five-item"},[_c('div',{staticClass:"five-item-title"},[_vm._v("100+")]),_c('div',[_vm._v("掌动开发游戏IP数")])]),_c('div',{staticClass:"five-item"},[_c('div',{staticClass:"five-item-title"},[_vm._v("Top10")]),_c('div',[_vm._v("杭州重点文化企业")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"icon-title"},[_c('img',{attrs:{"src":require("../assets/titleIcon.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("产品介绍")]),_c('div',{staticClass:"icon-title"},[_c('img',{attrs:{"src":require("../assets/titleIcon.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-box"},[_c('div',{staticClass:"line1"}),_c('div',{staticClass:"line2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-icon"},[_c('img',{attrs:{"src":require("../assets/phone.png"),"alt":""}})])
}]

export { render, staticRenderFns }